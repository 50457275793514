:root {
  --toastify-color-progress-light: none;
  --toastify-toast-width: 350px;
  --toastify-toast-top: max(48px, env(safe-area-inset-top))
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    top: max(64px, env(safe-area-inset-top));
    left: max(8px, env(safe-area-inset-left));
    right: max(8px, env(safe-area-inset-right));
    width: auto; 
  }

  .Toastify__toast {
    border-radius: var(--toastify-toast-bd-radius);
  }
}